
export const APP_AUTH_TOKEN = 'AUTH_TOKEN';

export async function saveToken(token: string) {
  window.localStorage.setItem(APP_AUTH_TOKEN, token)
}

export async function getToken() {
  return window.localStorage.getItem(APP_AUTH_TOKEN)
}

export async function removeToken() {
  window.localStorage.removeItem(APP_AUTH_TOKEN)
}

export async function getHeaders(): Promise<any> {
  const accessToken = await getToken();
  return {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + accessToken,
  };
}

export async function getHeadersMultiPart(): Promise<any> {
  const accessToken = await getToken();
  return {
    'Content-Type': 'multipart/form-data',
    Authorization: 'Bearer ' + accessToken,
  };
}