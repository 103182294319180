import { IconProps } from "./IconProps";

export default function Logo(props: IconProps) {
  const defaultWidth = 113;
  const defaultHeight = 46;

  let width = defaultWidth;
  let height = defaultHeight;

  if (props.size) {
    height = props.size;
    width = (defaultWidth * height) / defaultHeight;
  }

  return (
    <svg
      width={width || "113"}
      height={height || "46"}
      viewBox="0 0 113 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.1084 24.334H42.1819L38.9024 28.9028L34.9556 24.0322H25.0304L33.6028 34.2736L24.4521 44.9104H34.7609L38.1314 39.8401L42.2749 44.9104H52.491L43.7243 34.0738L52.1084 24.334Z"
        fill={props.color || "white"}
      />
      <path
        d="M59.8633 23.3394C53.9284 23.3394 49.1152 28.3021 49.1152 34.4245C49.1152 40.5469 53.9284 45.5083 59.8633 45.5083C65.7982 45.5083 70.609 40.5433 70.609 34.4245C70.609 28.3057 65.7962 23.3394 59.8633 23.3394ZM59.8633 39.7625C57.0031 39.7625 54.6843 37.3741 54.6843 34.4245C54.6843 31.4748 57.0031 29.0795 59.8633 29.0795C62.7234 29.0795 65.0371 31.4735 65.0371 34.4245C65.0371 37.3754 62.7215 39.7615 59.8633 39.7615V39.7625Z"
        fill={props.color || "white"}
      />
      <path
        d="M90.0639 25.8942C89.512 25.1439 88.7092 24.5407 87.6826 24.0819C86.6503 23.6197 85.5134 23.3913 84.289 23.3926C82.8914 23.3926 81.5965 23.6996 80.4274 24.3192C79.5783 24.7722 78.7966 25.3834 78.0824 26.1531V23.8617H72.2676V45.5341H78.4612V35.6734C78.4612 34.5223 78.4934 33.5557 78.5594 32.7802C78.6253 32.0048 78.7289 31.4162 78.849 31.0375C78.9624 30.6665 79.136 30.3178 79.3622 30.0059C79.591 29.6889 79.8717 29.415 80.1918 29.1964C80.8531 28.724 81.5846 28.4903 82.427 28.4903C83.0796 28.4903 83.6003 28.6554 84.0273 28.9594C84.4643 29.271 84.7739 29.6956 84.976 30.2776C85.0373 30.4608 85.0814 30.6496 85.1076 30.8414C85.1794 31.3296 85.2254 31.8215 85.2453 32.3148C85.2775 32.9202 85.2936 33.6461 85.2936 34.4817V45.5341H91.4892V32.1698C91.4892 30.5503 91.3926 29.2915 91.1851 28.3734C90.972 27.4557 90.6023 26.6222 90.0639 25.8942Z"
        fill={props.color || "white"}
      />
      <path
        d="M112.36 30.4562C111.929 28.9304 111.281 27.6412 110.407 26.6063C109.524 25.5572 108.417 24.7311 107.173 24.1925C105.932 23.6534 104.531 23.3926 102.985 23.3926C101.602 23.3926 100.321 23.6435 99.1609 24.1548C97.9977 24.6733 96.9605 25.4417 96.0494 26.4601C95.131 27.4811 94.444 28.7049 93.9925 30.1049C93.541 31.505 93.3145 33.0919 93.3145 34.8534C93.3145 36.3277 93.485 37.6888 93.8329 38.9281C94.1749 40.1657 94.6934 41.2874 95.3897 42.2765C96.254 43.501 97.4201 44.4677 98.7686 45.0775C100.131 45.6962 101.717 45.9999 103.517 45.9999C104.655 45.9999 105.712 45.8652 106.671 45.5922C107.638 45.318 108.512 44.9026 109.292 44.346C110.846 43.2381 111.983 41.6215 112.687 39.5381L112.855 39.0429L106.657 37.9674L106.548 38.349C106.413 38.8442 106.247 39.2575 106.053 39.5969C105.859 39.9362 105.644 40.1974 105.405 40.3951C105.163 40.5952 104.886 40.7476 104.59 40.8447C104.261 40.951 103.918 41.0028 103.573 40.9982C103.013 40.9982 102.511 40.8946 102.054 40.6949C101.604 40.4938 101.195 40.1934 100.817 39.7778C100.449 39.3737 100.166 38.8865 99.9702 38.3081C99.8135 37.8406 99.7127 37.3177 99.669 36.7291H112.989L112.994 36.2897C112.999 36.1121 112.999 35.9296 112.999 35.7579C113 33.7486 112.786 31.9813 112.36 30.4562ZM99.9969 31.0114C100.154 30.5106 100.409 30.0482 100.748 29.653C101.063 29.2818 101.452 28.9837 101.888 28.7785C102.312 28.5893 102.779 28.4903 103.304 28.4903C103.764 28.4844 104.22 28.5774 104.642 28.7633C105.047 28.9459 105.417 29.2185 105.76 29.5936C106.09 29.9547 106.354 30.4033 106.533 30.9606C106.686 31.4487 106.781 31.9546 106.813 32.4666H99.751C99.7777 31.9278 99.8588 31.4399 99.9969 31.0114Z"
        fill={props.color || "white"}
      />
      <path
        d="M29.4081 31.6837C27.2932 33.5549 24.5923 34.5826 21.7995 34.5788C15.333 34.5788 10.0861 29.1691 10.0861 22.4928C10.0861 15.8164 15.3314 10.4054 21.7995 10.4054C28.2676 10.4054 33.5161 15.8164 33.5161 22.4928H35.5801L38.6633 26.2231L41.4555 22.5426L43.6029 22.4931C43.6035 10.0726 33.8402 0 21.7995 0C9.75882 0 0 10.0726 0 22.4928C0 34.9129 9.76075 44.9836 21.7995 44.9836L22.5718 44.8096L31.8193 34.3725L29.4081 31.6837Z"
        fill={props.color || "white"}
      />
    </svg>
  );
}
