import { IconProps } from "./IconProps";

export default function Disburse(props: IconProps) {
  return (
    <svg
      width={props.size || 24}
      height={props.size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 11C22 11.5523 22.4477 12 23 12C23.5523 12 24 11.5523 24 11H22ZM10 21C10.5523 21 11 20.5523 11 20C11 19.4477 10.5523 19 10 19V21ZM1 8C0.447715 8 0 8.44772 0 9C0 9.55228 0.447715 10 1 10V8ZM23 10C23.5523 10 24 9.55228 24 9C24 8.44772 23.5523 8 23 8V10ZM16.6058 18.447C16.2153 18.8375 16.2153 19.4707 16.6058 19.8612C16.9964 20.2518 17.6295 20.2518 18.02 19.8612L16.6058 18.447ZM19.269 17.198L19.9761 17.9051C20.3667 17.5146 20.3667 16.8815 19.9761 16.4909L19.269 17.198ZM18.02 14.5348C17.6295 14.1443 16.9964 14.1443 16.6058 14.5348C16.2153 14.9254 16.2153 15.5585 16.6058 15.949L18.02 14.5348ZM14.9161 16.198C14.3638 16.198 13.9161 16.6458 13.9161 17.198C13.9161 17.7503 14.3638 18.198 14.9161 18.198V16.198ZM3 5H21V3H3V5ZM21 5C21.5523 5 22 5.44771 22 6H24C24 4.34315 22.6569 3 21 3V5ZM3 19C2.44772 19 2 18.5523 2 18H0C0 19.6569 1.34315 21 3 21V19ZM2 18V6H0V18H2ZM2 6C2 5.44772 2.44772 5 3 5V3C1.34315 3 0 4.34315 0 6H2ZM22 6V11H24V6H22ZM10 19H3V21H10V19ZM1 10H23V8H1V10ZM23.3961 17.198C23.3961 13.776 20.6201 11 17.198 11V13C19.5155 13 21.3961 14.8806 21.3961 17.198H23.3961ZM17.198 11C13.776 11 11 13.776 11 17.198H13C13 14.8806 14.8806 13 17.198 13V11ZM11 17.198C11 20.6201 13.776 23.3961 17.198 23.3961V21.3961C14.8806 21.3961 13 19.5155 13 17.198H11ZM17.198 23.3961C20.6201 23.3961 23.3961 20.6201 23.3961 17.198H21.3961C21.3961 19.5155 19.5155 21.3961 17.198 21.3961V23.3961ZM18.02 19.8612L19.9761 17.9051L18.5619 16.4909L16.6058 18.447L18.02 19.8612ZM19.9761 16.4909L18.02 14.5348L16.6058 15.949L18.5619 17.9051L19.9761 16.4909ZM14.9161 18.198H19.269V16.198H14.9161V18.198Z"
        fill={props.color || "#201D1D"}
      />
    </svg>
  );
}
