import { IconProps } from "./IconProps";

export default function Power(props: IconProps) {
  return (
    <svg
      width={props.size || 20}
      height={props.size || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 18.1249C5.51992 18.1249 1.875 14.4737 1.875 9.98622C1.875 7.54677 2.93945 5.25966 4.79531 3.71474C4.88197 3.64217 4.98208 3.5874 5.08993 3.55358C5.19778 3.51976 5.31124 3.50754 5.42382 3.51764C5.53639 3.52773 5.64587 3.55993 5.74599 3.61239C5.8461 3.66486 5.93488 3.73656 6.00725 3.82338C6.07961 3.91021 6.13414 4.01046 6.1677 4.11839C6.20126 4.22632 6.21321 4.33981 6.20285 4.45236C6.19248 4.56491 6.16002 4.67431 6.10732 4.7743C6.05461 4.87429 5.9827 4.9629 5.8957 5.03505C4.43281 6.2538 3.59375 8.05849 3.59375 9.98622C3.59375 13.5261 6.46758 16.4061 10 16.4061C13.5324 16.4061 16.4062 13.5261 16.4062 9.98622C16.4061 9.04019 16.1965 8.10592 15.7924 7.25053C15.3883 6.39514 14.7998 5.63986 14.0691 5.03896C13.9786 4.9683 13.9032 4.88022 13.8473 4.77992C13.7914 4.67962 13.7562 4.56914 13.7437 4.45499C13.7312 4.34085 13.7417 4.22537 13.7746 4.11536C13.8075 4.00535 13.8621 3.90305 13.9352 3.81449C14.0083 3.72594 14.0984 3.65294 14.2002 3.59979C14.302 3.54665 14.4134 3.51444 14.5278 3.50506C14.6423 3.49569 14.7574 3.50935 14.8665 3.54523C14.9756 3.58111 15.0764 3.63848 15.1629 3.71396C16.0892 4.47581 16.8352 5.4334 17.3474 6.51789C17.8595 7.60239 18.1251 8.78687 18.125 9.98622C18.125 14.4737 14.4801 18.1249 10 18.1249Z"
        fill={props.color || "white"}
      />
      <path
        d="M10 10.625C9.77208 10.625 9.55349 10.5345 9.39233 10.3733C9.23117 10.2121 9.14062 9.99355 9.14062 9.76562V2.73438C9.14062 2.50645 9.23117 2.28787 9.39233 2.12671C9.55349 1.96554 9.77208 1.875 10 1.875C10.2279 1.875 10.4465 1.96554 10.6077 2.12671C10.7688 2.28787 10.8594 2.50645 10.8594 2.73438V9.76562C10.8594 9.99355 10.7688 10.2121 10.6077 10.3733C10.4465 10.5345 10.2279 10.625 10 10.625Z"
        fill={props.color || "white"}
      />
    </svg>
  );
}
