const COLORS = {
  primary: "#201D1D",
  accent: "#FFFFFF",
  greyDark: "#8A8C8F",
  grey: "#B4B5B8",
  grey200: "#EEEEEE",
  grey500: "#9E9E9E",
  greyLight: "#EAEBEC",
  greyLighten: "#F6F6F6",
  info: "#2B4BF2",
  success: "#0AC46B",
  error: "#E92548",
  successLight: "#E1FDEF",
  errorLight: "#FFEBEF",
  warn: "#FABB43",
  warnLight: "#FFF3DC",
};

export default COLORS;
